import nuxtStorage from 'nuxt-storage'

export interface ILanguage {
  id: number
  name: string
}

export interface IGlobalText {
  id: number
  header: string
  call_btn: string
  mail_btn: string
  contact: string
  local: string
  discover: string
  language: ILanguage
  back_btn: string
}

export const useLanguage = defineStore('language', () => {
  const loading: Ref<boolean> = useState(() => true)
  const baseApiURL = useRuntimeConfig().public.apiUrl
  const languages = ref<ILanguage[] | undefined>(undefined)
  const selectedLanguage = ref<ILanguage>()
  const globalTextData = ref<IGlobalText>()

  const fetchGlobalTextData = async () => {
    loading.value = true

    const languageId = selectedLanguage.value?.id || 1
    try {
      globalTextData.value = await $fetch(
        `${baseApiURL}/api/globalText/${languageId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  function findLanguageById(
    id: number,
  ): ILanguage | undefined {
    return languages.value?.find(
      (language) => language.id === id,
    )
  }

  const fetchLanguages = async (companyId: number) => {
    loading.value = true
    try {
      languages.value = await $fetch(
        `${baseApiURL}/api/companyLanguage/${companyId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )

      const storedId =
        nuxtStorage.localStorage.getData('language')

      if (storedId) {
        const language = findLanguageById(storedId)
        if (language) {
          selectedLanguage.value = language
        } else {
          nuxtStorage.localStorage.removeItem('language')
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  const selectLanguage = async (lang: Ref) => {
    selectedLanguage.value = lang.value
    localStorageSave(lang.value.id)
    await fetchGlobalTextData()
  }
  const updateValue = async () => {
    localStorageSave(selectedLanguage.value!.id)
    await fetchGlobalTextData()
  }

  const localStorageSave = (id: number) => {
    nuxtStorage.localStorage.setData(
      'language',
      id,
      60,
      'd',
    )
  }

  return {
    loading,
    languages,
    selectedLanguage,
    globalTextData,
    updateValue,
    fetchLanguages,
    selectLanguage,
    fetchGlobalTextData,
  }
})
